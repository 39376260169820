<template>
  <div class="app-container">
    <div class="section-title">账户余额</div>
    <div class="balance-container">
      <div class="item">
        <span class="label">平台现金账户余额：</span>
        <span class="value">{{balance.cashBal}}元</span>
      </div>
      <div class="item">
        <span class="label">手续费收入余额：</span>
        <span class="value">{{balance.FeeInBal}}元</span>
      </div>
    </div>
    <div class="balance-container">
      <div class="item">
        <span class="label">手续费支出账户余额：</span>
        <span class="value">{{balance.FeeOutBal}}元</span>
      </div>
      <div class="item">
        <span class="label">营销账户余额：</span>
        <span class="value">{{balance.MarketingBal}}元</span>
      </div>
    </div>
    <div class="balance-container">
      <div class="item">
        <span class="label">担保账户余额：</span>
        <span class="value">{{balance.GuaranteeBal}}元</span>
      </div>
      <div class="item">
        <span class="label">代收渠道手续费余额：</span>
        <span class="value">{{balance.CollFeeAcBal}}元</span>
      </div>
    </div>
    <div style="margin-top: 10px">
      <el-button type="primary" size="mini" @click="tapChargeIn">充值</el-button>
      <el-button type="success" size="mini" @click="tapCashOut">提现</el-button>
      <el-button type="info" size="mini" @click="tapDownload">对账文件下载</el-button>
    </div>

    <el-dialog :title="form.type == 1 ? '充值' : '提现'" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="120px" style="width: 350px;">
        <el-form-item label="电子登记簿类型" prop="link_type">
          <el-select v-model="form.accTyp">
            <template v-if="form.type == 1">
              <el-option label="手续费支出电子登记簿" value="02"/>
              <el-option label="营销电子登记簿" value="03"/>
            </template>
            <template v-else>
              <el-option label="手续费收入电子登记簿" value="01"/>
              <el-option label="手续费支出电子登记簿" value="02"/>
              <el-option label="营销电子登记簿" value="03"/>
              <el-option label="代收渠道手续费电子登记簿" value="04"/>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="weight">
          <el-input type="number" v-model="form.amount"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

    <div class="section-title top-line">收支明细</div>
    <div class="filter-container">
      <el-radio-group v-model="listQuery.type" @change="handleFilter">
        <el-radio-button :label="0">手续费收入账户</el-radio-button>
        <el-radio-button :label="1">手续费支出账户</el-radio-button>
        <el-radio-button :label="2">营销账户</el-radio-button>
        <el-radio-button :label="3">代收渠道手续费账户</el-radio-button>
      </el-radio-group>
    </div>
    <div class="filter-container">
      <!-- <el-input v-model="listQuery.keyword" placeholder="请输入订单号" style="width: 220px;" clearable/> -->
      <el-date-picker v-model="dateArr" type="datetimerange" align="right" value-format="yyyy-MM-dd HH:mm:ss" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
    </div>
    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="订单号" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.tradeOrdNo }}
        </template>
      </el-table-column>
      <el-table-column label="交易金额" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.trAmt/100 }}
        </template>
      </el-table-column>
      <el-table-column label="交易日期" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.tradt }}
        </template>
      </el-table-column>
      <el-table-column label="交易时间" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.tratm }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="交易完成后账户余额" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.accBalance }}
        </template>
      </el-table-column> -->
      <el-table-column label="备注" min-width="100" align="center">
        <template slot-scope="scope">
          {{ scope.row.remk }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
var moment = require("moment");

export default {
  data() {
    return {
      balance: {},
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        type: 0,
        page: 1,
        limit: 10,
        keyword: '',
        startTime: '',
        endTime: '',
      },
      dialogFormVisible: false,
      form: {
        type: 1, // 1充值2提现
        accTyp: '',
        amount: 0,
      },
      btnLoading: false,
      dateArr: [moment().format("YYYY-MM-DD 00:00:00"),moment().format("YYYY-MM-DD 23:59:59")],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
    };
  },
  created() {
    this.getBalance();
    this.getList();
  },
  mounted() {},
  computed: {
  },
  watch: {
  },
  methods: {
    getBalance() {
      request({
        url: "/api/backend/ccb/platBalance",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.balance = response.data;
      });
    },
    getList() {
      if (!this.dateArr) {
        this.$message({
          type: "warning",
          message: "请选择筛选时间"
        });
        return;
      }
      this.listQuery.startTime = this.dateArr[0];
      this.listQuery.endTime = this.dateArr[1];
      this.listLoading = true;
      request({
        url: "/api/backend/ccb/platBill",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.Lists;
        this.total = parseInt(response.data.totCnt);
        this.listLoading = false;
      });
    },
    handleAccount(row) {
      this.$router.push('/ccb/userAccount?shop_id='+row.shop_id)
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    tapChargeIn() {
      this.form = {
        type: 1,
        accTyp: '',
        amount: 0,
      }
      this.dialogFormVisible = true
    },
    tapCashOut() {
      this.form = {
        type: 2,
        accTyp: '',
        amount: 0,
      }
      this.dialogFormVisible = true
    },
    saveData() {
      if (!this.form.accTyp) {
        this.$message({
          type: "warning",
          message: "请选择电子登记簿类型"
        });
        return;
      }
      if (!this.form.amount || this.form.amount <= 0) {
        this.$message({
          type: "warning",
          message: "请输入金额"
        });
        return;
      }
      this.btnLoading = true
      request({
        url: '/api/backend/ccb/chargeOrCash',
        method: 'post',
        data: this.form
      }).then(response => {
        this.btnLoading = false
        this.dialogFormVisible = false
        this.getBalance()
        this.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 2000
        })
        this.getList();
      }).catch((err) => {
        this.btnLoading = false
      })
    },
    tapDownload() {
      window.open(process.env.BASE_API + '/api/backend/ccb/billDownload', '_blank')
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.floor-container {
  display: inline-block;
  width: 160px;
  height: 160px;
  margin-right: 25px;
  margin-bottom: 25px;
}
.balance-container {
  display: flex;
  margin: 2px 0;
  .item {
    margin-right: 20px;
    width: 300px;
  }
  .label {
    color: #414141;
    width: 160px;
    display: inline-block;
  }
  .value {
    font-weight: bold;
    color: red;
    width: 80px;
  }
}
.section-title {
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
}
.top-line {
  margin-top: 20px;
  border-top: 1px solid #f0f0f0;
}
</style>
